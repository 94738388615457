<template>
    <div>
        <Header :title="'信息编辑'" :leftClick="goBack">
        </Header>

        <div style="width: 80%;margin: 20px auto;">
            <el-input v-model="kid_name" placeholder="请输入"></el-input>

            <div class="btn-edit-nick" @click="submitData">
                修改昵称
            </div>

        </div>


    </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import {
    updateKid
} from '../../api/app'
import {
    getAppUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            user_id: getAppUserId(),
            kid_id: this.$route.params.kid_id,
            kid_name: this.$route.params.kid_name,
        }
    },
    mounted() {
    },
    methods: {

        goBack() {
            this.$router.go(-1)
        },

        submitData() {
            let kid = this.kid_id
            if (kid == undefined || kid == null || kid == '') {
                kid = 'new'
            }
            updateKid(this.user_id, this.kid_id, this.kid_name).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success('更新成功～')
                    this.goBack()
                } else {
                    this.$message.success(res.data.msg)
                }
            })
        }
    }
})
</script>
<style lang="less" scoped>
/deep/ .el-input__inner {
    border-color: #5854b2;
    height: 42px;
    line-height: 42px;
    border-radius: 8px;
}

.btn-edit-nick {
    margin-top: 30px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    border-radius: 220px;
    background-color: #5854b2;
    height: 42px;
    line-height: 42px;
}
</style>